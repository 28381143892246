/*global window*/
'use strict';
export function createRedirect(store, route) {
    return function redirect(url) {
        store.dispatch({
            type: 'REDIRECT',
            url: url
        });
        return route(url);
    }
}
export function longSubmitButton(form) {
    var element = form.querySelector('button[type="submit"]');
    var unlockedText = element.dataset.unlockedText;
    var lockedText = element.dataset.lockedText;
    var span = element.getElementsByTagName('span')[0];
    var spinner = element.getElementsByTagName('i')[0];
    var timeout;

    function lock() {
        if (timeout) {
            return;
        }
        element.disabled = true;
        timeout = setTimeout(function () {
            lock();
        }, 750);
        span.innerText = lockedText;
        show(spinner);
        element.disabled = true;
    }
    return {
        lock: lock,
        unlock: function () {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            hide(spinner);
            element.disabled = false;
            span.innerText = unlockedText;
        }
    };
}
export function hide(ref) {
    if (ref.style.display === 'none') {
        return;
    }
    ref.style.display = 'none';
}
export function show(ref) {
    if (ref.style.display === '') {
        return;
    }
    ref.style.display = '';
}
export function disableForm(form) {
    var btnFormAction = form.querySelectorAll('button.btn-form-action');
    var i = 0;
    for (; i < btnFormAction.length; i++) {
        btnFormAction[i].disabled = true;
    }
}
export function catchXHRErr(status, reason) {
    return function (err) {
        var response;
        if (err && err.xhr && err.xhr.status === status) {
            if (!reason) {
                return true;
            }
            try {
                response = JSON.parse(err.xhr.responseText);
                return response.reason === reason;
            } catch (e) {
                return false;
            }
        }
    };
}
export function enableForm(form) {
    var btnFormAction = form.querySelectorAll('button.btn-form-action');
    var i = 0;
    for (; i < btnFormAction.length; i++) {
        btnFormAction[i].disabled = false;
    }
}
export function flashSuccess(store, message) {
    flash(store, 'success', message);
}
export function flashInfo(store, message) {
    flash(store, 'info', message);
}

function flash(store, type, message) {
    if (!store) {
        if (window.console) {
            console.error('flash', type, message);
        }
        return;
    }
    store.dispatch({
        type: 'ADD_ALERT',
        alert: {
            text: message,
            type: type
        }
    });
}
export function flashError(store, message) {
    flash(store, 'danger', message);
}
export function somethingIsWrongComeBackLater(store, e) {
    if (arguments.length !== 2) {
        console.error(new Error('wrong number of arguments'));
    }
    window.console && console.error('error loading tag', e);
    trackError(e);
    flashError(store, "__g('The page is not working properly. Please retry or come back later.')");
}
var track = {
    error: trackError
}

function trackEvent(action, category, label, value) {
    gtag('event', action, {
        'event_category': category,
        'event_label': label,
        'value': value
    });
}

function trackError(err) {
    gtag('event', 'exception', {
        'description': err,
        'fatal': false
    });
}
export function fadeInAnOut(el) {
    el.classList.remove('elementToFadeInAndOut');
    void el.offsetWidth; // force css animation
    el.classList.add('elementToFadeInAndOut');
}
export {
    track
};
