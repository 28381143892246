'use strict';

function CommonRoute(route, tagPath, title, htmlTag) {
    this.route = route;
    this.tagPath = tagPath;
    this.title = title;
    this.htmlTag = htmlTag;
}
export default [
    new CommonRoute('/sign_in..', 'common/sign_in', "__('Sign in - Shoptimiza')", 'sign_in'),
    new CommonRoute('/register_success..', 'common/register_success', "__('Your account has been created! - Shoptimiza')", 'register_success'),
    new CommonRoute('/register..', 'common/register', "__('Register - Shoptimiza')", 'register'),
//    new CommonRoute('/email_activation_success', 'common/email_activation_success', "__('Success! You have activated your account - Shoptimiza')", 'email_activation_success'),
    new CommonRoute('/password_reset..', 'common/password_reset', "__('Password reset - Shoptimiza')", 'password_reset'),
    new CommonRoute('/password_recovery', 'common/password_recovery', "__('Password recovery - Shoptimiza')", 'password_recovery'),
    new CommonRoute('/password_recovery_email_sent', 'common/password_recovery_email_sent', "__('Password recovery email sent - Shoptimiza')", 'password_recovery_email_sent')
];
//route('/sign_in..', m(clearFlashMessages, loadTag('common/sign_in'), hideTopbar, function (ctxt) {
//    document.title = "__('Sign in - Shoptimiza')";
//    ctxt.riot.mount('app', 'sign_in', ctxt);
//}));
//route('/register_success..', m(clearFlashMessages, loadTag('common/register_success'), hideTopbar, function (ctxt) {
//    document.title = "__('Your account has been created! - Shoptimiza')";
//    ctxt.riot.mount('app', 'register_success', ctxt);
//}));
//route('/register', m(clearFlashMessages, loadTag('common/register'), hideTopbar, function (ctxt) {
//    document.title = "__('Register - Shoptimiza')";
//    ctxt.riot.mount('app', 'register', ctxt);
//}));
//route('/email_activation_success', m(clearFlashMessages, loadTag('common/email_activation_success'), hideTopbar, function (ctxt) {
//    document.title = "__('Success! You have activated your account - Shoptimiza')";
//    ctxt.riot.mount('app', 'email_activation_success', ctxt);
//}));
//route('/password_reset..', m(clearFlashMessages, loadTag('common/password_reset'), hideTopbar, function (ctxt) {
//    document.title = "__('Password reset - Shoptimiza')";
//    ctxt.riot.mount('app', 'password_reset', ctxt);
//}));
//route('/password_recovery', m(clearFlashMessages, loadTag('common/password_recovery'), hideTopbar, function (ctxt) {
//    document.title = "__('Password recovery - Shoptimiza')";
//    ctxt.riot.mount('app', 'password_recovery', ctxt);
//}));
//route('/password_recovery_email_sent', m(clearFlashMessages, loadTag('common/password_recovery_email_sent'), hideTopbar, function (ctxt) {
//    document.title = "__('Password recovery email sent - Shoptimiza')";
//    ctxt.riot.mount('app', 'password_recovery_email_sent', ctxt);
//}));
