'use strict';
var api = {};

function statusSuccess(status) {
    return ('' + status)[0] === "2";
}

function parseResponse(input) {
    return input && JSON.parse(input);
}

function ajax(verb, url, data, callback) {
    if (typeof data === 'function') {
        callback = data;
        data = null;
    }
    var xhr = new XMLHttpRequest();
    xhr.open(verb, url);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.onload = function () {
        // status === 2XX ?
        var err;
        if (statusSuccess(xhr.status)) {
            return callback(null, xhr);
        }
        if (xhr.status === 403 && xhr.getResponseHeader('Location')) {
            document.location = xhr.getResponseHeader('Location');
            return;
        }
        err = new Error('XHR error');
        err.xhr = xhr;
        return callback(err);
    };
    xhr.onerror = function (e) {
        return callback(e);
    }
    if (data) {
        xhr.send(JSON.stringify(data));
    } else {
        xhr.send();
    }
}

function postJSON(url, data, callback) {
    ajax('POST', url, data, callback);
}

function putJSON(url, data, callback) {
    ajax('PUT', url, data, callback);
}

function deleteItem(url, callback) {
    ajax('DELETE', url, callback);
}

function getJSON(url, callback) {
    ajax('GET', url, callback);
}
api.loadUser = function loadUser(callback) {
    getJSON('/api/me', function (err, xhr) {
        var ret = parseResponse(xhr.responseText);
        if (err) {
            return callback(err);
        }
        return callback(null, ret);
    });
};

function simpleJSONResponse(callback) {
    // Check codes here https://en.wikipedia.org/wiki/List_of_HTTP_status_codes
    return function (err, xhr) {
        if (err) {
            return callback(err);
        }
        switch (xhr.status) {
        case 200: // Success
            return callback(null, parseResponse(xhr.responseText), xhr);
        case 201: // Success, resource created. Responds with the new resource plus a location header
            return callback(null, parseResponse(xhr.responseText), xhr);
        case 202: // Success, accepted. Usually this means that something (i.e. an email) has been enqueued
            return callback(null, true, xhr);
        case 204: // Success. No content.
            return callback(null, true, xhr);
        case 403: // Not 
            if (xhr.getResponseHeader('Location')) {
                console.error('403');
                document.location = xhr.getResponseHeader('Location');
                return;
            }
            default:
                err = new Error('XMLHttpRequest was not successful');
                err.xhr = xhr;
                return callback(err);
        }
    };
}
api.login = function login(email, password, callback) {
    var payload = {
        email: email,
        password: password
    };
    postJSON('/api/login', payload, simpleJSONResponse(callback));
};
api.getSites = function getSites(userId, callback) {
    getJSON('/api/users/' + userId + '/sites', simpleJSONResponse(callback));
};
api.getSite = function getSites(userId, siteId, callback) {
    var url = '/api/users/' + userId + '/sites/' + siteId;
    getJSON(url, simpleJSONResponse(callback));
};
api.createSite = function postSite(userId, payload, callback) {
    postJSON('/api/users/' + userId + '/sites/', payload, simpleJSONResponse(callback));
};
api.getSiteStatus = function (userId, siteId, callback) {
    var url = '/api/users/' + userId + '/sites/' + siteId + '/status';
    getJSON(url, simpleJSONResponse(callback));
};
api.createUser = function (user, callback) {
    postJSON('/api/users', user, simpleJSONResponse(callback));
};
api.requestPasswordRecoveryEmail = function requestPasswordRecoveryEmail(email, callback) {
    postJSON('/api/send_password_recovery_email/', {
        email: email
    }, simpleJSONResponse(callback));
};
api.resetPassword = function resetPassword(token, password, callback) {
    postJSON('/api/reset_password', {
        token: token,
        password: password
    }, simpleJSONResponse(callback));
};
api.resendActivationEmail = function resendActivationEmail(userId, token, callback) {
    var url = '/api/send_activation_email?t=' + encodeURIComponent(token);
    postJSON(url, {
        userId: userId
    }, simpleJSONResponse(callback));
};
api.getUser = function getUser(callback) {
    getJSON('/api/me', simpleJSONResponse(callback));
};
api.getPlan = function getPlan(id, callback) {
    var url = '/api/plans/' + id;
    getJSON(url, simpleJSONResponse(callback));
};
api.setSiteDomain = function updateSiteDomain(userId, siteId, newDomain, callback) {
    var payload = {
        domain: newDomain
    };
    putJSON('/api/users/' + userId + '/sites/' + siteId + '/domain', payload, simpleJSONResponse(callback));
};
api.getSiteStats = function getSiteStats(userId, siteId, callback) {
    getJSON('/api/users/' + userId + '/sites/' + siteId + '/stats', simpleJSONResponse(callback));
};
api.enablePullData = function enablePullData(userId, siteId, callback) {
    var url = '/api/users/' + userId + '/sites/' + siteId + '/pull-data';
    var payload = {
        pullData: true
    };
    putJSON(url, payload, simpleJSONResponse(callback));
};
api.disablePullData = function disablePullData(userId, siteId, callback) {
    var url = '/api/users/' + userId + '/sites/' + siteId + '/pull-data';
    var payload = {
        pullData: false
    };
    putJSON(url, payload, simpleJSONResponse(callback));
};
api.setPullUrl = function updatePulDataUrl(userId, siteId, pullUl, callback) {
    var url = '/api/users/' + userId + '/sites/' + siteId + '/pull-url';
    var payload = {
        url: pullUl
    };
    putJSON(url, payload, simpleJSONResponse(callback));
};
api.setPersonalData = function setPersonalData(userId, payload, callback) {
    var url = '/api/users/' + userId + '/personal-data';
    putJSON(url, payload, simpleJSONResponse(callback));
};
api.getCurrentUser = function getCurrentUser(userId, callback) {
    var url = '/api/users/' + userId
    getJSON(url, simpleJSONResponse(callback));
};
api.setPassword = function setEmail(userId, oldPassword, newPassword, callback) {
    var url = '/api/users/' + userId + '/password';
    var payload = {
        oldPassword: oldPassword,
        newPassword: newPassword
    };
    putJSON(url, payload, simpleJSONResponse(callback));
};
api.setEmail = function setEmail(userId, email, callback) {
    var url = '/api/users/' + userId + '/email';
    var payload = {
        email: email
    };
    putJSON(url, payload, simpleJSONResponse(callback));
};
api.setPreferredLanguage = function setPreferredLanguage(userId, lang, callback) {
    var url = '/api/users/' + userId + '/preferred-lang';
    var payload = {
        lang: lang
    };
    putJSON(url, payload, simpleJSONResponse(callback));
};
api.resetSharedSecret = function resetSharedSecret(userId, siteId, callback) {
    var url = '/api/users/' + userId + '/sites/' + siteId + '/reset-shared-secret';
    var payload = {};
    putJSON(url, payload, simpleJSONResponse(callback));
};
api.deleteSite = function deleteSite(userId, siteId, callback) {
    var url = '/api/users/' + userId + '/sites/' + siteId;
    deleteItem(url, simpleJSONResponse(callback));
};
api.createOurAffiliate = function createOurAffiliate(userId, payload, callback) {
    var url = '/api/affiliates/' + userId;
    putJSON(url, payload, simpleJSONResponse(callback));
};
api.getOurAffiliateDetails = function getOurAffiliateDetails(userId, callback) {
    var url = '/api/affiliates/' + userId;
    getJSON(url, simpleJSONResponse(callback));
};
api.getOurAffiliateStats = function getOurAffiliateStats(userId, callback) {
    var url = '/api/affiliates/' + userId + '/stats';
    getJSON(url, simpleJSONResponse(callback));
};
api.createSubscription = function setBillingDetails(userId, siteId, payload, callback) {
    var url = '/api/users/' + userId + '/sites/' + siteId + '/subscription';
    putJSON(url, payload, simpleJSONResponse(callback));
};
api.setDefaultBilling = function setDefaultBilling(userId, payload, callback) {
    var url = '/api/users/' + userId + '/billing/default';
    postJSON(url, payload, simpleJSONResponse(callback));
}
api.getBillingData = function getBillingData(userId, callback) {
    var url = '/api/users/' + userId + '/billing'
    getJSON(url, simpleJSONResponse(callback));
};
api.setAffiliatePayout = function setAffiliatePayout(userId, payload, callback) {
    var url = '/api/affiliates/' + userId + '/payout'
    putJSON(url, payload, simpleJSONResponse(callback));
};
api.getStripeSecretForSetupIntent = function getStripeSecretForSetupIntent(callback) {
    var url = '/api/stripe/setup-intent';
    getJSON(url, simpleJSONResponse(callback));
};
api.addStripeCard = function addStripeCard(userId, payload, callback) {
    var url = '/api/users/' + userId + '/billing/card';
    postJSON(url, payload, simpleJSONResponse(callback));
};
api.removeStripeCard = function removeStripeCard(userId, cardId, callback) {
    var url = '/api/users/' + userId + '/billing/card/' + cardId;
    deleteItem(url,  simpleJSONResponse(callback));
};
export default api;
