'use strict';
import {
    somethingIsWrongComeBackLater
} from './util';

export default function tagLoader() {
    var scripts = document.getElementsByTagName('script');
    var currentScript = document.currentScript;
    var tags = {};
    var ret = {};
    ret.loadTag = function (name, callback) {
        if (tags[name]) {
            return callback();
        }
        var tag = document.createElement('script');
        tag.type = 'riot/tag';
        tag.type = 'text/javascript';
        tag.onload = function () {
            tags[name] = true;
            return callback && callback();
        };
        tag.onerror = function (e) {
            somethingIsWrongComeBackLater(store, e);
            return callback && callback.apply(null, arguments);
        };
        document.body.appendChild(tag);
        tag.src = localeRoutePrefix + '/tags/' + name + '.js';
        return;
    }
    return ret;
};
