'use strict';

function flatten(array) {
    var i, el, args;
    for (i = 0; i < array.length; i++) {
        el = array[i];
        if (Array.isArray(el)) {
            array = [].concat(array);
            args = [i, 1].concat(el);
            Array.prototype.splice.apply(array, args);
            return flatten(array);
        }
    }
    return array;
}

function createMiddlewareStackCreator(context) {
    function F() {};
    F.prototype = context
    return function middlewareStack() {
        var stack = Array.prototype.slice.call(arguments),
            s = flatten(stack);
        return function () {
            var args = Array.prototype.slice.call(arguments),
                ctxt = new F(),
                i = 0;
            ctxt.params = args;
            ctxt.locals = {};

            function next() {
                var middleware = s[i++];
                if (!middleware) {
                    return;
                }
                return middleware(ctxt, next);
            }
            return next();
        }
    }
}
module.exports = createMiddlewareStackCreator
module.exports.flatten = flatten;
