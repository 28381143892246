'use strict';

function appendAlert(alerts, input) {
    var i = 0;
    for (; i < alerts.length; i++) {
        if (alerts[i].text === input.text) {
            alerts[i].counter++;
            return;
        }
    }
    input.counter = 1;
    alerts.push(input);
}

function deleteSite(state, id) {
    var sites = state.sites;
    var i = 0;
    var date = (new Date()).toString();
    state.activeSite.deletedAt = date;
    for (; i < sites.length; i++) {
        if (sites[i].id === id) {
            sites[i].deletedAt = date;
            return;
        }
    }
}

function updateSite(sites, site) {
    var i, s, siteId;
    if (!site) {
        return;
    }
    i;
    s = [].concat(sites);
    siteId = site.id;
    for (i = 0; i < s.length; i++) {
        if (s[i].id === siteId) {
            s[i] = site;
            return s;
        }
    }
    s.push(site);
    return s;
}
export default function reducer(state, action) {
    state = state || {};
    switch (action.type) {
    case 'CLEAR_ALERTS':
        state.alerts = state.alerts || [];
        state.alerts.length = 0;
        return state;
    case 'REDIRECT':
        state.url = action.url;
        return state;
    case 'SET_BILLING_DATA':
        state.billingData = action.billingData;
        return state;
    case 'SET_OUR_AFFILIATE_STATUS':
        state.affiliate = {
            isOurAffiliate: action.isOurAffiliate,
            paypalEmail: action.paypalEmail,
            vat: action.vat,
            address: action.address
        };
        return state;
    case 'LOGIN':
        state.user = action.user;
        return state;
    case 'SET_USER_DATA':
        state.user = action.user;
        return state;
    case 'SET_SITES':
        state.sites = action.sites;
        if (!state.sites) {
            state.siteDetails = null;
            state.activeSite = null;
        }
        return state;
    case 'SET_SIDEBAR_BUTTONS':
        state.sidebarItems = action.buttons;
        return state;
    case 'SET_SIDEBAR_ACTIVE_BUTTON':
        state.sidebarActiveButton = action.button;
        return state;
    case 'ADD_SITE':
        state.sites = state.sites || [];
        state.sites.push(action.site);
        return state;
    case 'UPDATE_SITE':
        state.sites = state.sites || [];
        state.sites = updateSite(state.sites, action.site);
        return state;
    case 'ACTIVE_SITE':
        state.activeSite = action.site;
        return state;
    case 'ADD_ALERT':
        state.alerts = state.alerts || [];
        appendAlert(state.alerts, action.alert);
        return state;
    case 'EXPIRED_TRIAL':
        state.expiredTrial = true;
        return state;
    case 'VIEW':
        state.alerts.length = 0;
        return state;
    case 'SHOW_TOPBAR':
        state.topbarVisibility = true;
        return state;
    case 'HIDE_TOPBAR':
        state.topbarVisibility = false;
        return state;
    case 'DELETE_SITE':
        deleteSite(state, action.id);
        return state;
    case 'WAITING_FOR_EMAIL_CONFIRMATION':
        return {
            newUser: action.newUser
        };
        // move to own reducer
    case 'SET_OUR_AFFILIATE_STATS':
        state.ourAffiliate = state.ourAffiliate || {};
        state.ourAffiliate.stats = action.stats;
        return state;
    case 'START_REQUIRED_PAYMENT_TRANSACTION':
        state.requiredPaymentTransaction = action.transaction;
        return state;
    default:
        return state;
    }
}
