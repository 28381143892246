'use strict';
import {
    setOurAffiliateStats,
    setOurAffiliateStatus
} from '../our-affiliate-reducer';
import api from '../api';
import shoptimizaConf from '../../lib/shoptimiza-conf';
import commonRoutes from '../common-routes';
import middlewareStack from '../../lib/middleware-stack';
import reducer from '../reducer';
//import riot from 'riot';
import route from 'riot-route';
import createTagLoader from '../tag-loader';
import {
    createStore
} from 'redux';
import {
    flashSuccess,
    flashError,
    createRedirect,
    somethingIsWrongComeBackLater
} from '../util';
import {
    isLoggedIn,
    createTagLoaderMiddleware,
    hideSidebar,
    createLoadCurrentUserMiddleware,
    createLoadSitesMiddleware,
    createLoadSiteDetailsMiddleware,
    clearFlashMessagesMiddleware,
    loadPlan,
    createHideTopbar,
    createShowTopbar
} from '../middleware';
route.base(localeRoutePrefix + '/');
var store = createStore(reducer, {
        mainRoute: '/',
        uiType: 'affiliates',
        locale: LOCALE,
        localeRoutePrefix: localeRoutePrefix,
        shoptimizaConf: shoptimizaConf
    },
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
var tagLoader = createTagLoader();
var loadTag = createTagLoaderMiddleware(tagLoader);
var loadCurrentUser = createLoadCurrentUserMiddleware(store);
var loadSites = createLoadSitesMiddleware(store);
var loadSiteDetails = createLoadSiteDetailsMiddleware(store);
var clearFlashMessages = clearFlashMessagesMiddleware(store);
var hideTopbar = createHideTopbar(store);
var showTopbar = createShowTopbar(store);
var defaultMiddlewares = [clearFlashMessages];
var ctxtProto = {};
ctxtProto.api = api;
ctxtProto.redirect = createRedirect(store, route);
ctxtProto.query = function () {
    return route.query();
};
ctxtProto.store = store;
ctxtProto.originalUrl = function () {
    return document.location.href.split(document.location.origin)[1];
}
ctxtProto.riot = riot;
var m = middlewareStack(ctxtProto);

function setSidebarForAffiliate(ctxt, next) {
    var state = ctxt.store.getState();
    store.dispatch({
        type: 'SET_SIDEBAR_BUTTONS',
        buttons: {
            dashboard: {
                href: '/',
                text: "__('Dasboard')"
            },
            payout: {
                href: '/payout',
                text: "__('Payout')"
            }
        }
    });
    return next();
}

function loadOurAffiliate(ctxt, next) {
    var state = ctxt.store.getState();
    var userId = state.user.id;
    if (state.isOurAffiliate) {
        return next();
    }
    ctxt.api.getOurAffiliateDetails(userId, function (err, data) {
        if (err && err.xhr.status === 404) {
            return ctxt.redirect('/onboarding');
        }
        if (err) {
            somethingIsWrongComeBackLater(ctxt.store, err);
        }
        if (data.deletedAt) {
            return ctxt.redirect('/not-found');
        }
        if (data && data.createdAt) {
            setOurAffiliateStatus(ctxt.store, data);
        }
        return next();
    });
}

function ensureNotYetAnAffiliate(ctxt, next) {
    var state = ctxt.store.getState();
    var userId = state.user.id;
    ctxt.api.getOurAffiliateDetails(userId, function (err, data) {
        if (err && err.xhr.status === 404) {
            return next();
        }
        if (err) {
            somethingIsWrongComeBackLater(ctxt.store, err);
        }
        return ctxt.redirect('/');
    });
}

function loadAffiliateStats(ctxt, next) {
    var state = ctxt.store.getState();
    var userId = state.user.id;
    ctxt.api.getOurAffiliateStats(userId, function (err, data) {
        if (err) {
            somethingIsWrongComeBackLater(ctxt.store, err);
        }
        setOurAffiliateStats(ctxt.store, data);
        return next();
    });
}
route('/', m(defaultMiddlewares, isLoggedIn, loadOurAffiliate, loadAffiliateStats, loadTag('affiliates/dashboard'), setSidebarForAffiliate, showTopbar, function (ctxt) {
    document.title = "__('Affiliate Dashboard')";
    ctxt.store.dispatch({
        type: 'SET_SIDEBAR_ACTIVE_BUTTON',
        button: 'dashboard'
    });
    ctxt.riot.mount('app', 'dashboard', ctxt);
}));
route('/onboarding', m(defaultMiddlewares, isLoggedIn, ensureNotYetAnAffiliate, loadTag('affiliates/onboarding'), hideSidebar, hideTopbar, function (ctxt) {
    document.title = "__('Affiliate Onboarding')";
    ctxt.riot.mount('app', 'onboarding', ctxt);
}));
route('/my_account', m(defaultMiddlewares, isLoggedIn, loadCurrentUser, hideSidebar, hideTopbar, loadTag('common/my_account'), function (ctxt) {
    document.title = "__('My Account - Shoptimiza')";
    ctxt.riot.mount('app', 'my_account', ctxt);
}));
route('/payout', m(defaultMiddlewares, isLoggedIn, loadOurAffiliate, loadTag('affiliates/payout'), setSidebarForAffiliate, showTopbar, function (ctxt) {
    document.title = "__('Payout Configuration - Shoptimiza')";
    ctxt.store.dispatch({
        type: 'SET_SIDEBAR_ACTIVE_BUTTON',
        button: 'payout'
    });
    ctxt.riot.mount('app', 'payout', ctxt);
}));
route('/onboarding/email_activation_success', m(defaultMiddlewares, isLoggedIn, loadCurrentUser, hideTopbar, hideSidebar, loadTag('common/email_activation_success'), function (ctxt) {
    document.title = "__('Success! You have activated your account - Shoptimiza')";
    ctxt.riot.mount('app', 'email_activation_success', ctxt);
}));
commonRoutes.forEach(function (x) {
    route(x.route, m(defaultMiddlewares, loadTag(x.tagPath), hideTopbar, function (ctxt) {
        document.title = x.title;
        ctxt.riot.mount('app', x.htmlTag, ctxt);
    }));
});
route(m(hideTopbar, function () {
    document.location = localeRoutePrefix + '/not-found';
}));

function init() {
    var i = 0;
    var tags = ['common/sidebar', 'common/flash', 'common/topbar', 'common/alerts'];

    function cb() {
        i++;
        if (i !== tags.length) {
            return;
        }
        riot.mount('*', {
            store: store,
            api: api,
            redirect: createRedirect(store, route)
        });
        route.start(true);
    }
    tags.forEach(function (t) {
        tagLoader.loadTag(t, cb);
    });
}
init();
