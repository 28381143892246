'use strict';
export function setOurAffiliateStats(store, data) {
    store.dispatch({
        type: 'SET_OUR_AFFILIATE_STATS',
        stats: data
    });
}
export function setOurAffiliateStatus(store, data) {
    var payload = {
        type: 'SET_OUR_AFFILIATE_STATUS',
        isOurAffiliate: true,
        billingAddressId: data.billingAddressId,
        paypalEmail: data.paypalEmail,
        vat: data.vat,
        address: {
            country: data.country,
            city: data.city,
            region: data.region,
            line1: data.line1,
            line2: data.line2,
            postalCode: data.postalCode
        }
    };
    store.dispatch(payload);
}
