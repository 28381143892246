'use strict';
// this file should be browser compatible
var validPlans = [8, 5, 6, 7];
var plans = {
    '1': {
        meta: {
            maxProducts: 3500
        },
        name: 'Free'
    },
    '2': {
        meta: {
            maxProducts: 5000
        },
        name: 'Starter'
    },
    '3': {
        meta: {
            maxProducts: 5000
        },
        name: 'Business'
    },
    '4': {
    // metered
        meta: {
            maxProducts: 3500
        },
        name: 'Free'
    },
    '5': {
        meta: {
            maxProducts: 5000
        },
        name: 'Starter'
    },
    '6': {
        meta: {
            maxProducts: 5000
        },
        name: 'Business'
    },
    '7': {
        meta: {
            maxProducts: 5000
        },
        name: 'Pro'
    },
    '8': {
    //small sites
        meta: {
            maxProducts: 5000
        },
        name: 'Free'
    },
    '10000': {
        meta: {
            maxProducts: 5000,
            freeTrial: true
        },
        name: 'Free Trial'
    }
};

function getValidPlans() {
    var ret = {};
    var id;
    var i = 0;
    for (; i < validPlans.length; i++) {
        id = '' + validPlans[i];
        ret[id] = plans[id];
    }
    return ret;
}
exports = module.exports = {
    validPlans: validPlans,
    plans: plans,
    defaultPlan: 10000, 
    currentTOC: 1,
    currentPrivacyPolicy: 1,
    freeTrial: 10000,
    isValidPlan: function (input) {
        var intInput = +input;
        return validPlans.indexOf(intInput) > -1;
    },
    getValidPlans: getValidPlans
};
