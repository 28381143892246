'use strict';
import shoptimizaConf from '../lib/shoptimiza-conf';
import {
    somethingIsWrongComeBackLater
} from './util';
export function notifyExpiredTrial(ctxt, next) {
    var i, now = Date.now(),
        createdAt, state = ctxt.store.getState();
    if (!state.activeSite) {
        return next();
    }
    if ('' + state.activeSite.planId !== '' + shoptimizaConf.freeTrial) {
        return next();
    }
    createdAt = state.activeSite.createdAt && new Date(state.activeSite.createdAt).valueOf()
    if (now - createdAt > 14 * 24 * 60 * 60 * 1000) { // 14 days old
        ctxt.store.dispatch({
            type: 'EXPIRED_TRIAL'
        });
    }
    return next();
}
export function hideSidebar(ctxt, next) {
    ctxt.store.dispatch({
        type: 'SET_SIDEBAR_ACTIVE_BUTTON'
    });
    return next();
}
export function isLoggedIn(ctxt, next) {
    var state;
    ctxt.api.getUser(function (err, user) {
        if (err) {
            somethingIsWrongComeBackLater(ctxt.store, err);
        }
        if (!user) {
            return ctxt.redirect('/sign_in?return=' + encodeURIComponent(ctxt.originalUrl()));
        }
        ctxt.store.dispatch({
            type: 'SET_USER_DATA',
            user: user
        });
        state = ctxt.store.getState();
        ctxt.user = state.user;
        return next();
    });
}
export function createTagLoaderMiddleware(tagLoader) {
    return function loadTag(name) {
        return function loadTagMiddleware(ctxt, next) {
            tagLoader.loadTag(name, function (err) {
                if (!err) {
                    return next();
                }
            });
        }
    }
}
export function createLoadCurrentUserMiddleware(store) {
    return function loadCurrentUser(ctxt, next) {
        ctxt.api.getCurrentUser(ctxt.user.id, function (err, user) {
            if (err) {
                return somethingIsWrongComeBackLater(ctxt.store, err);
            }
            ctxt.store.dispatch({
                type: 'SET_USER_DATA',
                user: user
            });
            ctxt.user = user;
            return next();
        });
    }
}
export function createLoadBillingData(store) {
    return function loadBillingData(ctxt, next) {
        ctxt.api.getBillingData(ctxt.user.id, function (err, data) {
            if (err) {
                return somethingIsWrongComeBackLater(ctxt.store, err);
            }
            ctxt.store.dispatch({
                type: 'SET_BILLING_DATA',
                billingData: data
            });
            return next();
        });
    };
};
export function zeroSitesRedirectToOnboarding(ctxt, next) {
    var sites = ctxt.locals.sites;
    if (!sites || sites.length === 0) {
        return ctxt.redirect('/onboarding');
    }
    return next();
}
export function createLoadSitesMiddleware(store) {
    return function loadSites(ctxt, next) {
        ctxt.api.getSites(ctxt.user.id, function (err, sites) {
            if (err) {
                return somethingIsWrongComeBackLater(ctxt.store, err);
            }
            if (sites && sites.length > 0) {
                ctxt.store.dispatch({
                    type: 'SET_SITES',
                    sites: sites
                });
                ctxt.locals.sites = sites;
                return next();
            }
            return next();
        });
    }
}
export function createLoadSiteDetailsMiddleware(store) {
    return function loadSiteDetails(ctxt, next) {
        var userId = ctxt.user.id;
        var siteId = ctxt.params[0];
        ctxt.api.getSite(userId, siteId, function (err, site) {
            if (err) {
                return somethingIsWrongComeBackLater(ctxt.store, err);
            }
            ctxt.locals.site = site;
            store.dispatch({
                type: 'UPDATE_SITE',
                site: site
            });
            store.dispatch({
                type: 'ACTIVE_SITE',
                site: site
            });
            return next();
        });
    }
}
export function clearFlashMessagesMiddleware(store) {
    return function clearFlashMessages(ctxt, next) {
        store.dispatch({
            type: 'CLEAR_ALERTS',
        });
        return next();
    }
}
export function loadPlan(ctxt, next) {
    var key = ctxt.params[1];
    ctxt.api.getPlan(key, function (err, plan) {
        if (err) {
            somethingIsWrongComeBackLater(ctxt.store, err);
        }
        ctxt.locals.plan = plan;
        return next();
    });
}
export function createHideTopbar(store) {
    return function hideTopbar(ctxt, next) {
        store.dispatch({
            type: 'HIDE_TOPBAR'
        });
        return next();
    };
}
export function createShowTopbar(store) {
    return function showTopbar(ctxt, next) {
        store.dispatch({
            type: 'SHOW_TOPBAR'
        });
        return next();
    };
}
